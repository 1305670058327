<template>
    <div v-loading="loading">
        <el-main style="padding: 0px;" v-if="modeaqui == 'verEmpleados'">
            <div
                style="display:flex; flex-direction:column; margin: auto; align-items:center; justify-content:center; background:white; border-radius: 5px; max-width: 630px; position: relative;">
                <span @click="cambiarModo(oldmode)" class="botoncerrar"><font-awesome-icon
                        :icon="['fas', 'xmark']" /></span>
                <h2 class="instrucciones" style="margin-top:0;">Panel de Usuarios</h2>
                <h3 style="margin-top: 0px;">{{ datos.centro }}</h3>
                <el-select v-model="busquedaCentro" filterable placeholder="Buscar centros"
                    style="width:100%; margin-bottom: 10px;">
                    <el-option key="vertodos" label="Ver todos los centros" value=""></el-option>
                    <el-option v-for="centro in centros" :key="centro" :label="centro" :value="centro">
                    </el-option>
                </el-select>
                <el-select v-model="busquedaEmpleado" filterable placeholder="Buscar usuarios" style="width:100%">
                    <el-option key="vertodos" label="Ver todos los empleados" value=""></el-option>
                    <el-option v-for="(item, index) in filteredEmpleados" :key="item + index" :label="item.nombre"
                        :value="item.nombre">
                    </el-option>
                </el-select>
                <div
                    style="display: flex; flex-wrap: wrap; width: 100%; flex-direction: row; justify-content: center; overflow-y: auto; max-height: 500px; margin-top: 10px;">
                    <span @click="abrirFichaEmpleado(empleado)" class="tarjetaempleado tooltip"
                        v-for="(empleado, index) in filteredEmpleados" :key="empleado + index"
                        :style="calcularColor(empleado, 'fondo')">
                        <img class="imgverempleados"
                            :style="`border-radius: 50%; margin-bottom: 10px; background: white; border: 3px solid ${calcularColorEstado(empleado)}; outline: 3px solid white; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); padding: 3px;`"
                            :src="`https://www.gravatar.com/avatar/${empleado.email}?d=https://cdn-icons-png.flaticon.com/512/403/403554.png?w=740&t=st=1689841343~exp=1689841943~hmac=c6cc07fa541b994409e820351829b5766ab2d175e6a73c5e1357f2c2004d250a`"
                            width="86px" />
                        <b>
                            <font-awesome-icon v-if="empleado.permisos" :icon="['fas', 'star']" />
                            <font-awesome-icon v-if="empleado.vacaciones && empleado.vacaciones.length"
                                :icon="['far', 'circle-exclamation']" />
                            {{ empleado.nombre }}</b>
                        <span class="tooltiptext">
                            <span
                                style="display: flex; justify-content: center; align-items: center; margin: 0px 10px;">
                                <span
                                    :style="`background-color: white; border-radius:50%; padding: 2px; display: inline-flex; margin-right: 5px; ${calcularColor(empleado, 'icono')}`"><font-awesome-icon
                                        :icon="['fas', 'circle']" /></span> {{ calcularEstado(empleado) }}</span>
                        </span>
                    </span>
                </div>
            </div>
        </el-main>
        <el-main v-if="modeaqui == 'fichaEmpleado'">
            <div
                style="display:flex; flex-direction:column; margin: auto; align-items:center; justify-content:center; background:white; padding: 20px; border-radius: 5px; max-width: 630px; position: relative;">
                <span @click="modeaqui = 'verEmpleados'" class="botoncerrar"><font-awesome-icon
                        :icon="['fas', 'arrow-left']" /></span>
                <h2 class="instrucciones" style="margin-top:0;">Ficha del empleado</h2>
                <div style="display:flex; align-items: center;">
                    <img class="imgverempleados"
                        :style="`border-radius: 50%; margin-right: 10px; background: white; border: 3px solid ${calcularColorEstado(empleadoSeleccionado)}; outline: 3px solid white; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); padding: 3px;`"
                        :src="`https://www.gravatar.com/avatar/${empleadoSeleccionado.email}?d=https://cdn-icons-png.flaticon.com/512/403/403554.png?w=740&t=st=1689841343~exp=1689841943~hmac=c6cc07fa541b994409e820351829b5766ab2d175e6a73c5e1357f2c2004d250a`"
                        width="50px" height="50px" />
                    <span style="text-align:left;">
                        <h3 style="margin: 0px;">{{ empleadoSeleccionado.nombre }}</h3>
                        <h4 style="margin: 0px; margin-top: 10px;">{{ empleadoSeleccionado.centro }}</h4>
                        <h4 v-if="empleadoSeleccionado.motivoAusenciaFutura != 'nada'"
                            style="margin: 0px; margin-top: 10px;">AUSENTE: {{ empleadoSeleccionado.motivoAusenciaFutura
                            }}
                        </h4>
                    </span>
                </div>
                <div style="display:flex; flex-direction:column; margin-top: 20px;">
                    <div style="display:flex;">
                        <div style="width: 250px; border: 1px solid darkgray; border-radius: 5px; margin-right: 5px;">
                            <h4>Fichajes de hoy</h4>
                            <hr style="margin:0px;">
                            <div
                                style="display:flex; flex-direction: column; align-items:start; margin: 10px; max-height: 200px; overflow-y:auto;">
                                <span v-for="registro in empleadoSeleccionado.fichajeshoy" :key="registro.hora"
                                    style="margin: 5px; font-size: 1.2rem;">
                                    <small>
                                        <b
                                            :style="`${registro.tipoRegistro == 'Inicio' || registro.tipoRegistro == 'Reanudacion' ? 'color: rgb(163, 203, 56);' : ''} ${registro.tipoRegistro == 'Pausa' ? 'color: rgb(249, 202, 36);' : ''} ${registro.tipoRegistro == 'Fin' ? 'color: rgb(255, 71, 87);' : ''}`"><font-awesome-icon
                                                :icon="registro.tipoRegistro == 'Inicio' || registro.tipoRegistro == 'Reanudacion' ? ['fas', 'arrow-right'] : ['fas', 'arrow-left']" /></b>
                                        <b>
                                            {{ registro.hora.split(':')[0] + ':' + registro.hora.split(':')[1] }}
                                            {{ registro.tipoRegistro === 'Pausa' ? registro.motivo : '' }}
                                        </b>
                                    </small>
                                </span>
                                <el-alert
                                    v-if="!empleadoSeleccionado.fichajeshoy || empleadoSeleccionado.fichajeshoy.length == 0"
                                    title="Sin registros" type="info" effect="dark" :closable="false">
                                </el-alert>
                            </div>
                        </div>
                        <div style="width: 250px; border: 1px solid darkgray; border-radius: 5px; margin-left: 5px;">
                            <h4>Últimos 30 días fichados</h4>
                            <hr style="margin:0px;">
                            <div
                                style="display:flex; flex-direction: column; align-items:start; margin: 10px; max-height: 200px; overflow-y:auto;">
                                <span v-for="(registro, index) in empleadoSeleccionado.fichajesmes" :key="index"
                                    style="margin: 5px; font-size: 1.2rem;">
                                    <small><b><font-awesome-icon style="color:#0abde3"
                                                :icon="['fas', 'calendar-alt']" /> {{
        index | formatearFechaISO }}</b></small>
                                </span>
                                <el-alert
                                    v-if="Object.keys(empleadoSeleccionado.fichajesmes).length === 0 || empleadoSeleccionado.fichajesmes.length == 0"
                                    title="Sin registros" type="info" effect="dark" :closable="false">
                                </el-alert>
                            </div>
                        </div>
                    </div>
                    <el-button @click="modeaqui = 'anadirRegistro'" style="width:100%; margin-top: 10px;"
                        type="primary">Modificar registros</el-button>
                    <el-button @click="modeaqui = 'programarAusencia'" style="width:100%; margin: 5px 0px;"
                        type="success">Programar ausencia</el-button>
                    <el-button v-if="!empleadoSeleccionado.fichajemovil" @click="alternarFichajeMovil()"
                        style="width:100%; margin:0px;" type="warning">Permitir fichaje móvil</el-button>
                    <el-button v-if="empleadoSeleccionado.fichajemovil" @click="alternarFichajeMovil()"
                        style="width:100%; margin:0px;" type="danger">Restringir fichaje móvil</el-button>
                </div>
            </div>
        </el-main>
        <el-main v-if="modeaqui == 'anadirRegistro'">
            <div
                style="display:flex; flex-direction:column; margin: auto; align-items:center; justify-content:center; background:white; padding: 20px; border-radius: 5px; max-width: 630px; position: relative;">
                <span @click="modeaqui = 'fichaEmpleado'" class="botoncerrar"><font-awesome-icon
                        :icon="['fas', 'arrow-left']" /></span>
                <h2 class="instrucciones" style="margin-top:0;">Modificar registros</h2>
                <div style="display:flex; align-items: center;">
                    <img class="imgverempleados"
                        :style="`border-radius: 50%; margin-right: 10px; background: white; border: 3px solid ${calcularColorEstado(empleadoSeleccionado)}; outline: 3px solid white; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); padding: 3px;`"
                        :src="`https://www.gravatar.com/avatar/${empleadoSeleccionado.email}?d=https://cdn-icons-png.flaticon.com/512/403/403554.png?w=740&t=st=1689841343~exp=1689841943~hmac=c6cc07fa541b994409e820351829b5766ab2d175e6a73c5e1357f2c2004d250a`"
                        width="50px" height="50px" />
                    <span style="text-align:left;">
                        <h3 style="margin: 0px;">{{ empleadoSeleccionado.nombre }}</h3>
                        <h4 style="margin: 0px; margin-top: 10px;">{{ empleadoSeleccionado.centro }}</h4>
                    </span>
                </div>
                <div
                    style="width: 490px; border: 1px solid darkgray; border-radius: 5px; margin-top: 20px; padding:10px;">
                    <b>Día</b>
                    <el-date-picker type="date" placeholder="Fecha"
                        style="width: 100%; margin-top: 10px; margin-bottom: 10px" v-model="formulario.dia"
                        format="dd/MM/yyyy" value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <div style="display:flex; margin-top: 20px;">
                    <div style="width: 250px; border: 1px solid darkgray; border-radius: 5px; margin-right: 5px;">
                        <h4>Nuevo registro</h4>
                        <hr style="margin:0px;">
                        <div style="margin:10px; text-align:left; max-height:200px; overflow-y:auto;">
                            <b>Hora</b>
                            <el-time-picker style="width: 100%; margin-top:10px; margin-bottom: 10px;"
                                placeholder="Hora" v-model="formulario.hora" value-format="HH:mm:ss"></el-time-picker>
                            <b>Tipo de registro</b>
                            <el-select style="margin-top: 10px; width: 100%; margin-bottom: 10px;"
                                placeholder="Registro" v-model="formulario.tipo">
                                <el-option v-for="tipo in tiposRegistro" :key="tipo.value" :label="tipo.value"
                                    :value="tipo.value">
                                </el-option>
                            </el-select>
                            <b v-if="formulario.tipo == 'Pausa'">Motivo de la pausa</b>
                            <el-input v-if="formulario.tipo == 'Pausa'"
                                style="width:100%; margin-top: 10px; margin-bottom:10px;" placeholder="Motivo pausa"
                                v-model="formulario.motivo"></el-input>
                            <b>Comentario</b>
                            <el-input style="width:100%; margin-top: 10px; margin-bottom:10px;" placeholder="Comentario"
                                v-model="formulario.comentario"></el-input>
                            <el-button style="width: 100%" type="primary" @click="enviarRegistro">Añadir</el-button>
                        </div>
                    </div>
                    <div style="width: 250px; border: 1px solid darkgray; border-radius: 5px; margin-left: 5px;">
                        <h4>Fichajes</h4>
                        <hr style="margin:0px;">
                        <el-drawer :visible.sync="drawerRegistro" direction="ttb" size="100%" style="padding: 20px;">
                            <div style="height: 100%; width: -webkit-fill-available; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                            <template v-if="registroFiltrado">
                                <template v-if="registroFiltrado.ubicacion && registroFiltrado.ubicacion.includes(',')">
                                    <l-map style="height: 300px; margin-bottom: 20px;" :zoom="15" :center="registroFiltrado.ubicacion.split(', ')">
                                        <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"></l-tile-layer>
                                        <l-marker :lat-lng="registroFiltrado.ubicacion.split(', ')"></l-marker>
                                    </l-map>
                                </template>
                                <table style="text-align: right;">
                                    <tbody>
                                        <tr>
                                            <th>
                                                Tipo registro 
                                            </th>
                                            <td>
                                                {{ registroFiltrado.tipoRegistro }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Fecha 
                                            </th>
                                            <td>
                                                {{ registroFiltrado.fecha }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Hora 
                                            </th>
                                            <td>
                                                {{ registroFiltrado.hora }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                               Motivo 
                                            </th>
                                            <td>
                                                {{ registroFiltrado.motivo }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                               Comentario 
                                            </th>
                                            <td>
                                                {{ registroFiltrado.comentario }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Teletrabajo 
                                            </th>
                                            <td>
                                                {{ registroFiltrado.teletrabajo ? 'Sí': 'No' }}
                                            </td>
                                        </tr>
                                        <tr v-if="registroFiltrado.ubicacion">
                                            <th>
                                                Ubicación 
                                            </th>
                                            <td>
                                                {{ registroFiltrado.ubicacion }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                            <el-button v-if="fichajesFiltrados && registroFiltrado && fichajesFiltrados.indexOf(registroFiltrado) == fichajesFiltrados.length-1" style="margin-top: 20px" type="danger"
                                @click="borrarRegistro(registroFiltrado); drawerRegistro = false;">Eliminar
                                registro</el-button>
                            </div>
                        </el-drawer>
                        <div
                            style="display:flex; flex-direction: column; align-items:start; margin: 10px; max-height: 200px; overflow-y:auto;">
                            <span v-for="(registro, index) in fichajesFiltrados" :key="registro + index"
                                @click="registroFiltrado = registro; drawerRegistro = true;"
                                style="margin: 5px; font-size: 1.2rem;">
                                <small>
                                    <b
                                        :style="`${registro.tipoRegistro == 'Inicio' || registro.tipoRegistro == 'Reanudacion' ? 'color: rgb(163, 203, 56);' : ''} ${registro.tipoRegistro == 'Pausa' ? 'color: rgb(249, 202, 36);' : ''} ${registro.tipoRegistro == 'Fin' ? 'color: rgb(255, 71, 87);' : ''}`"><font-awesome-icon
                                            :icon="registro.tipoRegistro == 'Inicio' || registro.tipoRegistro == 'Reanudacion' ? ['fas', 'arrow-right'] : ['fas', 'arrow-left']" /></b>
                                    <b>
                                        {{ registro.hora.split(':')[0] + ':' + registro.hora.split(':')[1] }}
                                        {{ registro.tipoRegistro === 'Pausa' ? registro.motivo : '' }}
                                    </b>
                                </small>
                            </span>
                            <el-alert v-if="!fichajesFiltrados || fichajesFiltrados.length == 0" title="Sin registros"
                                type="info" effect="dark" :closable="false">
                            </el-alert>
                        </div>
                    </div>
                </div>
            </div>
        </el-main>
        <el-main v-if="modeaqui == 'programarAusencia'">
            <div
                style="display:flex; flex-direction:column; margin: auto; align-items:center; justify-content:center; background:white; padding: 20px; border-radius: 5px; max-width: 630px; position: relative;">
                <span @click="modeaqui = 'fichaEmpleado'" class="botoncerrar"><font-awesome-icon
                        :icon="['fas', 'arrow-left']" /></span>
                <h2 class="instrucciones" style="margin-top:0;">Programar ausencias</h2>
                <div style="display:flex; align-items: center;">
                    <img class="imgverempleados"
                        :style="`border-radius: 50%; margin-right: 10px; background: white; border: 3px solid ${calcularColorEstado(empleadoSeleccionado)}; outline: 3px solid white; box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); padding: 3px;`"
                        :src="`https://www.gravatar.com/avatar/${empleadoSeleccionado.email}?d=https://cdn-icons-png.flaticon.com/512/403/403554.png?w=740&t=st=1689841343~exp=1689841943~hmac=c6cc07fa541b994409e820351829b5766ab2d175e6a73c5e1357f2c2004d250a`"
                        width="50px" height="50px" />
                    <span style="text-align:left;">
                        <h3 style="margin: 0px;">{{ empleadoSeleccionado.nombre }}</h3>
                        <h4 style="margin: 0px; margin-top: 10px;">{{ empleadoSeleccionado.centro }}</h4>
                        <h4 v-if="empleadoSeleccionado.motivoAusenciaFutura != 'nada'"
                            style="margin: 0px; margin-top: 10px;">
                            AUSENTE: {{ empleadoSeleccionado.motivoAusenciaFutura
                            }}
                        </h4>
                    </span>
                </div>
                <div style="display:flex;">
                    <div
                        style="width: 250px; border: 1px solid darkgray; border-radius: 5px; margin-top: 20px; margin-right: 5px;">
                        <h4>Programar ausencia</h4>
                        <div style="margin:10px; text-align:left;">
                            <b>Día de inicio</b>
                            <el-date-picker type="date" placeholder="Fecha"
                                style="width: 100%; margin-top: 10px; margin-bottom: 10px"
                                v-model="formularioAusencia.diaInicio" format="dd/MM/yyyy"
                                value-format="yyyy-MM-dd"></el-date-picker>
                            <el-checkbox v-model="formularioAusencia.establecerFin">Programar fin para la
                                ausencia</el-checkbox>
                            <br>
                            <br>
                            <template v-if="formularioAusencia.establecerFin">
                                <b>Día de fin</b>
                                <el-date-picker type="date" placeholder="Fecha"
                                    style="width: 100%; margin-top: 10px; margin-bottom: 10px"
                                    v-model="formularioAusencia.diaFin" format="dd/MM/yyyy"
                                    value-format="yyyy-MM-dd"></el-date-picker>
                            </template>
                            <b>Tipo de ausencia</b>
                            <el-select style="margin-top: 10px; width: 100%; margin-bottom: 10px;"
                                placeholder="Registro" v-model="formularioAusencia.tipo">
                                <el-option v-for="tipo in tiposAusencia" :key="tipo.value" :label="tipo.value"
                                    :value="tipo.value">
                                </el-option>
                            </el-select>
                            <b>Comentario</b>
                            <el-input style="width:100%; margin-top: 10px; margin-bottom:10px;" placeholder="Comentario"
                                v-model="formulario.comentario"></el-input>
                            <el-button style="width: 100%" type="primary"
                                @click="enviarAusenciaProgramada">Añadir</el-button>
                        </div>
                    </div>
                    <div
                        style="width: 250px; border: 1px solid darkgray; border-radius: 5px; margin-top: 20px; margin-left: 5px;">
                        <h4>Ausencias programadas</h4>
                        <div
                            style="display:flex; flex-direction: column; align-items:start; margin: 10px; max-height: 300px; overflow-y:auto; text-align:left;">
                            <span v-for="(registro, index) in empleadoSeleccionado.ausencias" :key="registro + index"
                                @click="borrarAusenciaProgramada(registro)" style="margin: 5px; font-size: 1.2rem;">
                                <b>{{ registro.motivo }}</b><br>
                                <small>Inicio: {{ registro.diaInicio }}</small><br>
                                <small v-if="registro.establecerFin">Fin: {{ registro.diaFin }}</small>
                                <small v-if="!registro.establecerFin">Duración indefinida</small>
                            </span>
                            <el-alert
                                v-if="!empleadoSeleccionado.ausencias || empleadoSeleccionado.ausencias.length == 0"
                                title="Sin registros" type="info" effect="dark" :closable="false">
                            </el-alert>
                        </div>
                    </div>
                </div>
            </div>
        </el-main>
        <audio id="audiosuccess" controls style="display:none">
            <source type="audio/mp3" src="@/assets/success.mp3">
        </audio>
    </div>
</template>
<script>
import {
    verEmpleados,
    enviarRegFichajeResp,
    fichajeAusenciaResp,
    alternarFichajeMovil
} from "@/api.js";
const moment = require('moment');
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')

export default {
    name: "PanelEmpleados",
    filters: {
        formatearFechaISO(fecha) {
            fecha = new Date(fecha);

            const año = fecha.getFullYear();
            const mes = String(fecha.getMonth() + 1).padStart(2, "0"); // Agrega un 0 delante si el mes es < 10
            const día = String(fecha.getDate()).padStart(2, "0"); // Agrega un 0 delante si el día es < 10

            const fechaFormateada = `${día}/${mes}/${año}`;

            return fechaFormateada; // Mostrará "2023-08-30"

        }
    },
    data() {
        return {
            verEmpleados: [],
            loading: true,
            busquedaEmpleado: [],
            busquedaCentro: [],
            modeaqui: 'verEmpleados',
            responsable: true,
            empleadoSelec: '',
            formulario: {
                dia: moment().format('YYYY-MM-DD'),
                hora: moment().format('HH:mm:ss'),
                tipo: '',
                motivo: '',
                comentario: '',
            },
            formularioAusencia: {
                diaInicio: moment().format('YYYY-MM-DD'),
                diaFin: moment().format('YYYY-MM-DD'),
                tipo: 'Enfermedad',
                comentario: '',
            },
            tiposAusencia: [
                {
                    value: 'Enfermedad'
                },
                {
                    value: 'Maternidad'
                },
                {
                    value: 'Vacaciones'
                },
                {
                    value: 'Fallecimiento'
                }
            ],
            drawerRegistro: false,
            registroFiltrado: {}
        };
    },
    props: {
        token: {},
        datos: {},
        oldmode: {},
    },
    computed: {
        tiposRegistro() {
            if (!this.fichajesFiltrados || !this.fichajesFiltrados.length) {
                return [
                    {
                        value: 'Inicio'
                    }
                ]
            }
            let ultimoRegistro = this.fichajesFiltrados[this.fichajesFiltrados.length - 1];
            if (ultimoRegistro.tipoRegistro == 'Inicio') {
                return [
                    {
                        value: 'Pausa'
                    },
                    {
                        value: 'Fin'
                    }
                ]
            }
            if (ultimoRegistro.tipoRegistro == 'Pausa') {
                return [
                    {
                        value: 'Reanudacion'
                    },
                ]
            }
            if (ultimoRegistro.tipoRegistro == 'Inicio' || ultimoRegistro.tipoRegistro == 'Reanudacion') {
                return [
                    {
                        value: 'Pausa'
                    },
                    {
                        value: 'Fin'
                    },
                ]
            }
            if (ultimoRegistro.tipoRegistro == 'Fin' || ultimoRegistro.tipoRegistro == 'Fin') {
                return [
                ]
            }
            return [
                {
                    value: 'Inicio'
                },
                {
                    value: 'Pausa'
                },
                {
                    value: 'Reanudacion'
                },
                {
                    value: 'Fin'
                }
            ]
        },
        filteredEmpleados() {
            let empleados = [...this.verEmpleados];
            if (this.busquedaEmpleado && this.busquedaEmpleado != '') {
                empleados = empleados.filter((empleado) => { return empleado.nombre.toLowerCase().includes(this.busquedaEmpleado.toLowerCase()) })
            }
            if (this.busquedaCentro && this.busquedaCentro != '') {
                empleados = empleados.filter((empleado) => { return empleado.centro.toLowerCase().includes(this.busquedaCentro.toLowerCase()) })
            }
            return empleados;
        },
        fichajesFiltrados() {
            if (this.esHoy(this.formulario.dia)) {
                return [...this.empleadoSeleccionado.fichajes[this.formulario.dia]].reverse();
            }
            return this.empleadoSeleccionado.fichajes[this.formulario.dia];
        },
        empleadoSeleccionado() {
            return this.verEmpleados.filter((empleado) => { return this.empleadoSelec == empleado.token })[0]
        },
        centros() {
            let centros = new Set();
            for (let i = 0; i < this.verEmpleados.length; i++) {
                centros.add(this.verEmpleados[i].centro)
            }
            return centros;
        }
    },
    async mounted() {
        this.loading = true;
        this.verEmpleados = await (await verEmpleados({ token: this.token }));
        this.verEmpleados = this.verEmpleados.empleados;
        this.loading = false;
    },
    watch: {
        busquedaCentro() {
            this.busquedaEmpleado = '';
        }
    },
    methods: {
        async borrarAusenciaProgramada(registro) {
            let data = {
                tokenUser: registro.token,
                diaInicio: registro.diaInicio,
                diaFin: registro.diaFin,
                motivo: registro.motivo,
                token: this.datos.token,
                borrar: true
            };
            let resp = await (await fichajeAusenciaResp(data));
            if (resp.status == 200) {
                this.$notify({
                    title: 'Correcto',
                    message: 'El registro se ha eliminado correctamente.',
                    type: 'success'
                });
                this.verEmpleados = await (await verEmpleados({ token: this.token }));
                this.verEmpleados = this.verEmpleados.empleados;
            } else {
                this.$notify({
                    title: 'Ha ocurrido un error',
                    message: 'No tienes permisos para esta modificación.',
                    type: 'error'
                });
            }
        },
        async enviarAusenciaProgramada() {
            let data = {
                token: this.datos.token,
                diaInicio: this.formularioAusencia.diaInicio,
                diaFin: this.formularioAusencia.diaFin,
                motivo: this.formularioAusencia.tipo,
                tokenUser: this.empleadoSeleccionado.token,
                establecerFin: this.formularioAusencia.establecerFin
            };
            let resp = await (await fichajeAusenciaResp(data));
            if (resp.status == 200) {
                this.$notify({
                    title: 'Correcto',
                    message: 'El registro se ha añadido correctamente.',
                    type: 'success'
                });
                this.verEmpleados = await (await verEmpleados({ token: this.token }));
                this.verEmpleados = this.verEmpleados.empleados;
            } else {
                this.$notify({
                    title: 'Ha ocurrido un error',
                    message: 'No tienes permisos para esta modificación.',
                    type: 'error'
                });
            }
        },
        async borrarRegistro(registro) {
            this.loading = true;
            try {
                let data = {
                    token: this.token,
                    tipo: this.formulario.tipo,
                    motivo: this.formulario.motivo,
                    ubicacion: this.ubicacion,
                    teletrabajo: this.teletrabajo,
                    fichajeResponsable: true,
                    tokenUser: this.empleadoSelec,
                    fecha: this.formulario.dia,
                    hora: this.formulario.hora,
                    nombre: this.datos.nombre,
                    borrar: true,
                    registro
                };
                let resp = await (await enviarRegFichajeResp(data));
                this.verEmpleados = await (await verEmpleados({ token: this.token }));
                this.verEmpleados = this.verEmpleados.empleados;
                this.loading = false;
                return resp;
            } catch (e) {
                this.loading = false;
            }
            this.loading = false;
        },
        hoy() {
            return moment().format('yyyy-MM-dd')
        },
        esHoy(fecha) {
            if (moment().format('YYYY-MM-DD') == fecha) {
                return true;
            }
            return false;
        },
        async enviarRegistro() {
            if (this.formulario.dia == '' || this.formulario.hora == '' || this.formulario.tipo == '' || this.formulario.comentario == '') {
                this.$notify({
                    title: 'Error',
                    message: 'Rellena todos los datos para añadir el registro.',
                    type: 'error'
                });
                return;
            }
            this.loading = true;
            try {
                let data = {
                    token: this.token,
                    tipo: this.formulario.tipo,
                    motivo: this.formulario.motivo,
                    ubicacion: this.ubicacion,
                    teletrabajo: this.teletrabajo,
                    fichajeResponsable: true,
                    tokenUser: this.empleadoSelec,
                    fecha: this.formulario.dia,
                    hora: this.formulario.hora,
                    nombre: this.datos.nombre,
                    comentario: this.formulario.comentario
                };
                let resp = await (await enviarRegFichajeResp(data));
                this.verEmpleados = await (await verEmpleados({ token: this.token }));
                this.verEmpleados = this.verEmpleados.empleados;
                this.formulario = {
                    dia: moment().format(this.formulario.dia),
                    hora: moment().format('HH:mm:ss'),
                    tipo: '',
                    motivo: '',
                    comentario: '',
                }
                if (resp.status == 200) {
                    this.$notify({
                        title: 'Correcto',
                        message: 'El registro se ha añadido correctamente.',
                        type: 'success'
                    });
                } else {
                    this.$notify({
                        title: 'Ha ocurrido un error',
                        message: 'No tienes permisos para esta modificación.',
                        type: 'error'
                    });
                }

                this.loading = false;
                return resp;
            } catch (e) {
                this.loading = false;
            }
        },
        abrirFichaEmpleado(empleado) {
            if (empleado.permisos) {
                this.modeaqui = 'fichaEmpleado';
                this.empleadoSelec = empleado.token;
            }
        },
        calcularColorEstado(empleado) {
            let respuesta = '';
            if (empleado.ultimofichaje && empleado.ultimofichaje.tipoRegistro != null) {
                switch (empleado.estado) {
                    case 'Disponible':
                        respuesta = '#44fc97';
                        break;
                    case 'Ausente':
                        respuesta = '#ffc000';
                        break;
                    case 'Reunido':
                        respuesta = '#48dbfb';
                        break;
                    case 'Ocupado':
                        respuesta = '#ff4757';
                        break;
                }
                if (empleado.ultimofichaje['tipoRegistro'] == 'Fin') {
                    respuesta = 'white';
                }
                if (empleado.ultimofichaje['tipoRegistro'] == 'Pausa') {
                    respuesta = '#ffc000';
                }
                if (empleado.estado.includes('Reunido')) {
                    respuesta = '#48dbfb';
                }
            } else {
                respuesta = 'black';
            }
            return respuesta;
        },
        shakeAnimation() {
            this.shake = true;
            setTimeout(() => {
                this.shake = false;
            }, 820); // timeout value depending on the duration of the animation
        },
        cambiarModo(modo) {
            this.$emit("mode", modo);
        },
        calcularColor(data, tipo) {
            let respuesta = 'background-color: black; color: white;';
            if (data.vacaciones && data.vacaciones.length && data.vacaciones.IDTIPODIA != 16 && data.vacaciones.IDTIPODIA != 2) {
                let colores = ['', '#fff200', '#f6e58d', '#D868DF', '#FAA830', '#B7A0DE', '#A7FBE7', '#FEE2CB', '#FBBEF4', '#8FC0BA', '#AD7D61', '#6D6D6D', '#3498db', '#d7d7d7', '#e74c3c', '#e67e22', '#f6e58d', '#48dbfb', '#8395a7', '#5f27cd', '#2d3436', '#10ac84', '#82589f', '#ff6b81', '#f368e0', '#ff9ff3', '#c7ecee', '#ff9ff3', '#7f8c8d', '#ffbe76', '#f8a5c2'];
                let colorestext = ['', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#000000', '#000000', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'];
                respuesta = `background-color: ${colores[data.vacaciones[0].IDTIPODIA]}; color: ${colorestext[data.vacaciones[0].IDTIPODIA]};`;
                return respuesta;
            }
            if (data.motivoAusenciaFutura != 'nada') {
                switch (data.motivoAusenciaFutura) {
                    case 'Enfermedad':
                        respuesta = 'background-color: #01a3a4; color: white;';
                        return respuesta;
                    case 'Maternidad':
                        respuesta = 'background-color: #f368e0; color: white;';
                        return respuesta;
                    case 'Vacaciones':
                        respuesta = 'background-color: #ffff85; color: black;';
                        return respuesta;
                    case 'Fallecimiento':
                        respuesta = 'background-color: #576574; color: white;';
                        return respuesta;
                }
            }
            !data.ultimofichaje ? data.ultimofichaje = { tipoRegistro: null } : ''
            switch (data.ultimofichaje.tipoRegistro) {
                case 'Inicio':
                case 'Reanudacion':
                    tipo == 'fondo' ? respuesta = 'background-color: ' + this.calcularColorEstado(data) + '; color: white;' : respuesta = 'color: ' + this.calcularColorEstado(data) + '';
                    if (tipo == 'estado') {
                        respuesta = 'box-shadow: 0px 0px 10px 0px ' + this.calcularColorEstado(data) + ';';
                    }
                    if (data.ultimofichaje.teletrabajo) {
                        tipo == 'fondo' ? respuesta = 'background-color: #8dc26c; color: white;' : respuesta = 'color: #8dc26c';
                        if (tipo == 'estado') {
                            respuesta = 'box-shadow: 0px 0px 10px 0px #8dc26c;';
                        }
                    }
                    break;
                case 'Pausa':
                    tipo == 'fondo' ? respuesta = 'background-color: #ffc000; color: white;' : respuesta = 'color: #ffc000';
                    if (tipo == 'estado') {
                        respuesta = 'box-shadow: 0px 0px 10px 0px #ffc000;';
                    }
                    break;
                case 'Fin':
                    tipo == 'fondo' ? respuesta = 'background-color: white; color: black;' : respuesta = 'color: #FCFCFC';
                    break;
                default:
                    tipo == 'fondo' ? respuesta = 'background-color: black; color: white;' : respuesta = 'color: #000';
                    break;
            }
            return respuesta;
        },
        calcularEstado(empleado) {
            let respuesta = 'Inactivo';
            if (empleado.vacaciones && empleado.vacaciones.length && empleado.vacaciones.IDTIPODIA != 16 && empleado.vacaciones.IDTIPODIA != 2) {
                let nombres = ['Reactivado', 'Vacaciones', 'Solicitar Vacaciones', 'Guardias', 'Rec. Guardias', 'Dias Extras', 'Dias de Permiso', 'Personales', 'Plan Especial', 'Libre Disposicion', 'Periodo Anterior', 'Otras Solicitudes', 'Festivos', 'Fines de Semana', 'Rechazados', 'Cancelados', 'Solicitud', 'Matrimonio', 'Accidente / Enfermedad grave', 'Fuerza mayor', 'Fallecimiento', 'Mudanza', 'Cumplimiento deber', 'Examen', 'Lactancia', 'Examen prenatal / Preparación parto', 'Cumpleaños', 'Cancelar solicitud', 'Otros', 'Cancelación solicitada', 'Lactancia (1 hora)'];
                return nombres[empleado.vacaciones[0].IDTIPODIA]
            }
            if (empleado.motivoAusenciaFutura != 'nada') {
                return 'Ausente: ' + empleado.motivoAusenciaFutura;
            }
            if (empleado.ultimofichaje) {
                switch (empleado.ultimofichaje.tipoRegistro) {
                    case 'Inicio':
                    case 'Reanudacion':
                        respuesta = empleado.estado;
                        break;
                    case 'Pausa':
                        respuesta = 'Pausa (' + empleado.ultimofichaje.motivo + ')';
                        break;
                    case 'Fin':
                        respuesta = 'Fin de jornada';
                        break;
                    case null:
                        respuesta = 'Inactivo';
                        break;
                }
            }
            return respuesta;
        },
        async alternarFichajeMovil() {
            let data = {
                token: this.token,
                empleado: this.empleadoSeleccionado
            };
            let resp = await (await alternarFichajeMovil(data));
            this.verEmpleados = await (await verEmpleados({ token: this.token }));
            this.verEmpleados = this.verEmpleados.empleados;
            console.log(resp);
        }
    }
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono&display=swap');

#mainpinpad {
    text-align: center;

    .instrucciones {
        margin: 10px;
        transition: 0.5s;

        &.red {
            color: rgba(255, 0, 0, 0.699);
        }
    }

    .avatar {
        border-radius: 50%;
        margin: 10px;
        background: white;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        padding: 3px;

        @media(max-width: 990px) {
            display: none;
        }
    }

    #contenedorSalida {
        display: flex;
        justify-content: center;
        margin: auto;
        border-radius: 5px;
        min-height: 50px;
        border: 1px solid #e9e9e9;
        width: 270px;
        margin-bottom: 25px;
        padding: 0px 10px;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 30px;
            font-size: 1.8rem;
            margin: 2px;
            padding: 4px;
        }
    }

    .dni-switch {
        margin: 30px 0px;
    }

    .el-switch__label.is-active {
        color: #000000;
    }
}

#mainopciones {
    text-align: center;

    #contenedorCards {
        width: 50%;
        margin: auto;
        transition: 0.5s;

        @media (max-width: 1600px) {
            width: 75%;
        }

        @media (max-width: 500px) {
            width: 100%;
        }

        #datosusuario {
            display: flex;
            padding: 10px;
            background: #ffffffb8;
            border: 3px solid #d3d3d361;
            border-radius: 10px;
            margin: auto;
            margin-bottom: 10px;
            justify-content: flex-start;
            transition: 0.5s;

            @media (min-width: 990px) {
                display: none
            }

            img {
                border-radius: 50%;
                margin-bottom: 10px;
                padding: 3px;
                margin: 10px 20px 10px 10px;
            }

            span {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: left;

                h2 {
                    font-weight: normal;
                    margin: 0;
                    margin-bottom: 0px;
                }

                h3 {
                    font-weight: normal;
                    margin: 0;
                }
            }
        }

        #contenedorCronometros {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: wrap;
            margin-bottom: 20px;

            .contador {
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
                background: white;
                border-radius: 50%;

                span {
                    position: absolute;
                    font-size: 2rem;
                    transition: 1s;
                }
            }
        }

        .contenedorSelector {
            display: flex;
            background: #ffffff;
            border-radius: 10px;
            margin: auto;
            justify-content: center;
            margin-bottom: 10px;

            &.pauseList {
                flex-wrap: wrap;

                div {
                    width: calc(50% - 30px);
                }

                .botonvolver {
                    width: 100%;
                    font-size: 1rem;
                    margin: 5px;
                    background-color: rgba(249, 202, 36, 0.15);
                    border: none;
                    color: rgb(249, 202, 36);
                }
            }

            div {
                border-radius: 5px;
                width: 100%;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 5px;

                button {
                    width: 50px;
                    height: 50px;

                    span {
                        i {
                            font-size: 1.5rem;
                        }

                        svg {
                            font-size: 1.5rem;
                        }
                    }
                }

                &.green {
                    background: #a3cb3826;
                    color: #A3CB38;

                    button {
                        background-color: #A3CB38;
                        border-color: #A3CB38;
                    }
                }

                &.yellow {
                    background: #f9ca2426;
                    color: #f9ca24;

                    button {
                        background-color: #f9ca24;
                        border-color: #f9ca24;
                    }
                }

                &.red {
                    background: #ff475726;
                    color: #ff4757;

                    button {
                        background-color: #ff4757;
                        border-color: #ff4757;
                    }
                }
            }
        }
    }
}

#mainselectortarea {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .contenedorbusqueda {
        @media(min-width: 480px) {
            width: 400px;
        }
    }

    .contenedorreceptores {
        @media(min-width: 480px) {
            width: 400px;
        }

        padding:10px;
        max-height: 50vh;
        overflow-y:auto;

        li {
            list-style: none;
            padding: 10px;
            background: #d5dae8;
            margin: 0px 0px 10px 0px;
            border-radius: 5px;
            color: #071c64;
            cursor: pointer;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                fill: #071c64;
                transition: 0.3s;
            }

            &:hover {
                background: #071c64;
                color: white;

                svg {
                    fill: white;
                }
            }

            &:active {
                background: #041038;
                color: white;
            }
        }

    }
}

#contenedorajustes {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: left;
    width: 50%;
    background: white;
    margin: auto;
    border-radius: 5px;
    position: relative;

    @media (max-width: 1600px) {
        width: 75%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }

    .datosusuario {
        display: flex;
        padding: 10px;
        border-radius: 10px;
        justify-content: flex-start;

        img {
            border-radius: 50%;
            margin-bottom: 10px;
            padding: 3px;
            margin: 10px 20px 10px 10px;
        }

        span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;

            h2 {
                font-weight: normal;
                margin: 0;
                margin-bottom: 0px;
            }

            h3 {
                font-weight: normal;
                margin: 0;
            }
        }
    }

    h3 {
        margin: 10px 0px 0px 0px;
    }

    div {
        display: flex;
        background: #ffffff;
        border-radius: 10px;
        margin-top: 10px;
        justify-content: center;
        margin-bottom: 10px;

        div {
            border-radius: 5px;
            width: 100%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 5px;
            transition: 0.5s;

            button {
                width: 50px;
                height: 50px;
                transition: 0.5s;
            }
        }
    }
}

.botoncerrar {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
    border: 2px solid darkgray;
    color: darkgray;
    border-radius: 50%;
    font-size: 1.5rem;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    align-items: center;
    transition: 0.5s;

    &:hover {
        background: darkgray;
        color: white;
    }

    &:active {
        transition: 0s;
        background: rgb(104, 104, 104);
        color: white;
    }
}

h2 {
    color: #727272;
    font-weight: 500;
    margin-bottom: 50px;
}


.tarjetaempleado {
    width: 160px;
    height: 160px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

    @media(max-width: 655px) {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        height: auto;
        margin: 5px;
        box-shadow: none;

        img {
            width: 20px;
            margin-right: 10px;
            margin-bottom: 0px !important;
        }
    }
}

ul.contenedorNumeros {
    display: flex;
    padding: 0px;
    margin: 0 auto;
    list-style: none;
    flex-wrap: wrap;
    max-width: 300px;
    justify-content: center;

    li {
        width: 80px;
        height: 80px;
        background-color: #f9f9f9;
        border: 1px solid #dedede;
        border-radius: 50%;
        display: flex;
        align-items: Center;
        justify-content: center;
        font-size: 1.5rem;
        margin: 5px;
        color: #585858;
        font-weight: 500;
        cursor: pointer;
        transition: 0.4s;

        &:hover {
            background-color: #dedede;
            border: 1px solid #a0a0a0;
        }

        &.danger {
            color: #F56C6C;
            background-color: rgb(253, 226, 226);
            border-color: #F56C6C;

            &:hover {
                background-color: #F56C6C;
                color: rgb(253, 226, 226);
            }
        }

        &.success {
            color: #67C23A;
            background-color: rgb(225, 243, 216);
            border-color: #67C23A;

            &:hover {
                color: rgb(225, 243, 216);
                background-color: #67C23A;
            }
        }
    }
}

.selectorcentro {
    width: 60%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    background-color: #f9f9f9;
    border: 1px solid #dedede;
    color: #585858;
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    transition: 1s;
    cursor: pointer;

    &.enabled {
        &:hover {
            background-color: #dedede;
            border-color: #f9f9f9;
        }
    }

    &.disabled {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        cursor: not-allowed;
    }
}

h1 {
    margin: auto;
    color: gray;
}

.selectorestado {
    border-radius: 50%;
    border: 3px solid transparent;
    background: rgba(0, 0, 0, 0.5);
    width: 50px;
    height: 50px;
    position: relative;
    opacity: 0;
    transition: 0.3s;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
    }

    &.selected {
        opacity: 1;
    }

    &:active {
        background: rgba(0, 0, 0, 0.5);
    }
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.apply-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

/* Tooltip container */
.tooltip {
    position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
    opacity: 0;
    width: 250px;
    background-color: rgba(0, 0, 0, 0.795);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    z-index: 99999;
    width: 180px;
    bottom: 0;
    left: 50%;
    margin-left: -90px;
    transition: 0.5s;
    /* Use half of the width (120/2 = 60), to center the tooltip */

    @media(max-width: 655px) {
        width: 100%;
        margin-left: 0px;
        left: 0;
    }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    opacity: 1;
}

.el-loading-mask {
    background-color: none;
}

.el-drawer__body {
    flex: 1;
    display: flex!important;
    overflow: auto!important;
    flex-direction: column!important;
    /* align-content: center; */
    justify-content: center!important;
    align-items: center!important;
}
th {
    padding: 0px 10px;
}
</style>