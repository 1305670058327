<template>
  <div id="app">
    <div id="botonajustes" v-if="ventana < 992 && botonFlotante == 'true'">
      <el-button :class="botonAjustes ? 'girar' : ''" @click="girarBoton(true)"
        :style="`width: 50px; height: 50px;  transition: 1s; background-color: ${datoscolor.colortexto}; border-color:  ${datoscolor.colortexto};`"
        type="primary" circle><font-awesome-icon style="font-size:1.2rem;" :icon="['fas', 'cog']" /></el-button>
      <el-button v-if="centro.selcentro" @click="mode = 'verEmpleados'; girarBoton(false)"
        :style="`width: 50px; height: 50px; background-color: ${datoscolor.colortexto}; border-color:  ${datoscolor.colortexto};`"
        type="primary" circle
        :disabled="mode == 'options' ? false : '' || mode == 'seleccioninicio' ? false : ''"><font-awesome-icon
          style="font-size:1.2rem;" :icon="['fas', 'users']" /></el-button>
    </div>
    <div>
      <el-row id="rowprincipal" :gutter="10">
        <div v-if="cabecera != 'false'" id="contenedorlogo" :style="`background: ${datoscolor.color}`">
          <img v-if="centro.selcentro == true" id="logoempresa" :src="`${datos.logo ? datos.logo : logoenv}`"
            @load="calcularColorLogo" />
          <img v-else id="logoempresa" :src="centro.logo" @load="calcularColorLogo" />
        </div>
        <el-col id="colizquierda" :style="datos.color" :xs="24" :md="12" :cols="12">
          <div class="instrucciones" :style="`background-color:${datoscolor.color};`">
            <div class="instrucciones-msg">
              <template>
                <div class="contenedorContador">
                  <h1 :style="`color: ${datoscolor.colortexto}; font-weight: revert;`">{{ hora }}</h1>
                  <div v-if="datos['bienvenida'] != 'Bienvenid@'"
                    style="display: flex; text-align: left; align-items: center; justify-content:space-between; background: rgba(0, 0, 0, 0.26); padding: 10px; border-radius: 10px; color: white; transition: 0.5s; margin-bottom: 10px; min-width: 300px; height: 77px;">
                    <span style="display: flex; align-items:center;">
                      <img
                        :style="`border-radius: 50%; border: 3px solid transparent; outline:3px solid ${calcularColorEstado()}; position:absolute; transition: 1s;`"
                        :src="`https://www.gravatar.com/avatar/${datos.email}?d=https://cdn-icons-png.flaticon.com/512/403/403554.png?w=740&t=st=1689841343~exp=1689841943~hmac=c6cc07fa541b994409e820351829b5766ab2d175e6a73c5e1357f2c2004d250a`"
                        width="50px" />
                      <span @click="selectorestado = !selectorestado;"
                        :class="`selectorestado ${selectorestado ? 'selected' : ''}`">
                        <font-awesome-icon v-if="!selectorestado" :icon="['fas', 'pencil-alt']" />
                        <font-awesome-icon v-if="selectorestado" :icon="['fas', 'arrow-left']" />
                      </span>
                      <div style="display:flex; flex-direction: column; margin-left: 10px;">
                        <p v-if="!selectorestado"
                          style="margin: 0px; font-weight: 400; font-size: 1.3rem; margin-bottom: 5px;">{{
                            datos.bienvenida }}</p><small v-if="!selectorestado">{{ status }}</small>
                        <p v-if="selectorestado"
                          style="margin: 0px; font-weight: 400; font-size: 1.2rem; margin-bottom: 5px;">Estado:</p>
                        <div v-if="selectorestado" style="display:flex; flex-direction: row;">
                          <span @click="estado = 'Disponible'" class="estado"><font-awesome-icon
                              style="color: #44fc97; font-size:1.6rem;"
                              :icon="['fas', 'circle']" /><span><font-awesome-icon :icon="['far', 'smile']" />
                              Disponible</span></span>
                          <span @click="estado = 'Ausente'" class="estado"><font-awesome-icon
                              style="color: #ffc000; font-size:1.6rem;"
                              :icon="['fas', 'circle']" /><span><font-awesome-icon :icon="['fas', 'door-open']" />
                              Ausente</span></span>
                          <span @click="estado = 'Reunido'" class="estado"><font-awesome-icon
                              style="color: #48dbfb; font-size:1.6rem;"
                              :icon="['fas', 'circle']" /><span><font-awesome-icon :icon="['far', 'handshake']" />
                              Reunión</span></span>
                          <span @click="estado = 'Ocupado'" class="estado"><font-awesome-icon
                              style="color: #ff4757; font-size:1.6rem;"
                              :icon="['fas', 'circle']" /><span><font-awesome-icon :icon="['fas', 'ban']" />
                              Ocupado</span></span>
                        </div>
                        <!-- <p style="margin: 0px; font-weight: 400; font-size: 0.8rem;">Estado: Trabajando</p> -->
                      </div>
                    </span>
                    <img v-if="centro.selcentro == true" @click="girarBoton(true)" class="iconoconfig"
                      style="max-width: 50px;" src="@/assets/configuracion.svg" />
                    <img v-else @click="girarBoton(true)" class="iconosalir" style="max-width: 50px;"
                      src="@/assets/cerrar-sesion.svg" />
                  </div>
                  <el-collapse-transition>
                    <div
                      style="display: flex; text-align: left; align-items: center; justify-content: center; background: rgba(0, 0, 0, 0.26); padding: 10px; border-radius: 10px; color: white; transition: 0.5s; margin-bottom: 10px; min-width: 300px;">
                      <span
                        style="font-size: 4rem; margin-right: 10px; display:flex; flex-direction: column; align-items: center;"><b>{{
                          obtener('dia') }}</b><b style="font-size: 1.5rem;"></b></span>
                      <span style="display:flex; flex-direction: column; justify-content:center;">
                        <span style="margin: 5px;"><b>{{ obtener('diasem').charAt(0).toUpperCase() +
                          obtener('diasem').slice(1) }}</b></span>
                        <span style="margin: 5px;">{{ obtener('mes').charAt(0).toUpperCase() + obtener('mes').slice(1)
                        }}</span>
                      </span>
                      <span v-if="registros[0]['tipoRegistro'] != null"
                        style="display:flex; flex-direction: column; justify-content:center; margin-left: 30px;">
                        <span v-for="registro in registrosalreves" :key="registro.hora"
                          style="margin: 5px; font-size: 1.2rem;">
                          <b
                            :style="`${registro.tipoRegistro == 'Inicio' || registro.tipoRegistro == 'Reanudacion' ? 'color: rgb(163, 203, 56);' : ''} ${registro.tipoRegistro == 'Pausa' ? 'color: rgb(249, 202, 36);' : ''} ${registro.tipoRegistro == 'Fin' ? 'color: rgb(255, 71, 87);' : ''}`"><font-awesome-icon
                              :icon="registro.tipoRegistro == 'Inicio' || registro.tipoRegistro == 'Reanudacion' ? ['fas', 'arrow-right'] : ['fas', 'arrow-left']" /></b>
                          <b>{{ registro.hora.split(':')[0] + ':' + registro.hora.split(':')[1] }}</b>
                        </span>
                      </span>
                    </div>
                  </el-collapse-transition>
                </div>
              </template>
            </div>
            <div v-show="mostrarLogo != 'false'">
              <img v-if="centro.selcentro == true" id="logoempresa" :src="`${datos.logo ? datos.logo : logoenv}`"
                @load="calcularColorLogo" />
              <img v-else id="logoempresa" :src="centro.logo" @load="calcularColorLogo" />
            </div>
            <span id="version" :style="`color: ${datoscolor.colortexto};`">{{ this.centro.version }}</span>
          </div>
          <div id="botonajustes" v-if="ventana >= 992 && this.centro.selcentro == true && !botonFlotante == 'false'">
            <el-button @click="mode = 'verEmpleados'; girarBoton(false)"
              :style="`width: 50px; height: 50px; background-color: ${datoscolor.colortexto}; border-color:  ${datoscolor.colortexto};`"
              type="primary" circle
              :disabled="mode == 'options' ? false : '' || mode == 'seleccioninicio' ? false : '' || mode == 'opciones' ? false : ''"><font-awesome-icon
                style="font-size:1.2rem;" :icon="['fas', 'users']" /></el-button>
          </div>
        </el-col>
        <el-col :xs="24" :md="12" :cols="12">
          <div class="grid-content" style="height: 100%">
            <PinPad @finish="finish" @registros="obtenerRegistros" @updateOptions="options = false" :options="options"
              :estado="estado" @limpiarEstado="estado = ''" @mode="(mode) => this.mode = mode" :modo="mode"
              @estado="(estado) => this.status = estado" :datoscolor="datoscolor"
              @datoscentro='(centro) => this.centro = centro' @verEmpleados="mode = 'verEmpleados'"
              @abrirOptions="girarBoton(true)" />
          </div>
          <span id="version" :style="`color: ${datoscolor.colortexto};`">{{ this.centro.version }}</span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import PinPad from "@/components/PinPad.vue";
const moment = require('moment');
import ColorThief from 'colorthief';
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')
console.log(moment().format('H:m:s'))
export default {
  name: "App",
  components: {
    PinPad,
  },
  computed: {
    registrosalreves() {
      let registros = this.registros.slice();
      registros.reverse();
      return registros;
    },
    porcentajeHoras() {
      let tiempo = moment(this.datos['horasTrabajo'], 'HH:mm')
      let horastrabajo = tiempo.hour() * 60 + tiempo.minute();
      let horastrabajadas = this.hh * 60 + this.mm;
      return (horastrabajadas * 100) / horastrabajo;
    },
    porcentajeMinutos() {
      let tiempo = moment(this.datos['horasTrabajo'], 'HH:mm')
      let horastrabajo = tiempo.hours();
      let horastrabajadas = this.hh
      if (horastrabajo == horastrabajadas) {
        return (this.mm * 100) / tiempo.minute();
      } else {
        return (this.mm * 100) / 60;
      }
    },
    estadoHoras() {
      if (this.porcentajeHoras >= 100) {
        return '#27ae60';
      }
      if (this.registros[0]['tipoRegistro'] == 'Pausa') {
        return '#e6a23c';
      } else {
        return '#406d9b';
      }
    }
  },
  data() {
    return {
      datos: [],
      datoscolor: [],
      registros: [
        {
          tipoRegistro: null,
          hora: "00:00:00",
        },
      ],
      logoenv: process.env.VUE_APP_LOGO,
      longitud: "",
      latitud: "",
      interval: "",
      mode: "",
      ventana: window.innerWidth,
      options: false,
      horasTrabajo: "",
      botonAjustes: false,
      estado: '',
      status: '',
      imagenvisible: true,
      selectorestado: false,
      centro: {
        selcentro: true,
      },
      hora: '00:00',
      solicitarFoto: process.env.VUE_APP_SOLICITARFOTO,
      botonFlotante: process.env.VUE_APP_BOTONFLOTANTE,
      cabecera: process.env.VUE_APP_CABECERA,
      mostrarLogo: process.env.VUE_APP_MOSTRARLOGO,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.calcularColorLogo();
    });
    setTimeout(this.calcularColorLogo, 3000);
    this.hora = this.obtener('hora');
    this.interval = setInterval(() => {
      this.init();
      this.hora = this.obtener('hora');
    }, 1000);
    window.addEventListener('resize', () => {
      this.ventana = window.innerWidth;
    }
    );
  },
  watch: {
    status() {
      this.selectorestado = false;
    },
    registros() {
      this.actualizarEstado()
    },
    datos() {
      let tiempo = moment(this.datos['horasTrabajo'], 'HH:mm')
      this.horasTrabajo = tiempo.minute() != 0 ? tiempo.hour() + "h y " + tiempo.minute() + "m" : tiempo.hour() + "h"
    },
  },
  methods: {
    calcularColorEstado() {
      let respuesta = '';
      switch (this.status) {
        case 'Disponible':
          respuesta = '#44fc97';
          break;
        case 'Ausente':
          respuesta = '#ffc000';
          break;
        case 'Reunido':
          respuesta = '#48dbfb';
          break;
        case 'Ocupado':
          respuesta = '#ff4757';
          break;
      }
      if (this.status.includes('Reunido')) {
        respuesta = '#48dbfb';
      }
      return respuesta;
    },
    async calcularColorLogo() {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      let imagen = document.getElementById('logoempresa').src;
      image.src = imagen;
      image.onload = () => {
        const colorThief = new ColorThief();
        const color = colorThief.getColor(image);
        let colortexto = this.darkenColor(color, 40);
        console.log(colortexto);
        this.datoscolor.colorfondobotones = 'rgba(' + color[0] + ', ' + color[1] + ', ' + color[2] + ', 0.2)';
        this.datoscolor.colorbotones = 'rgba(' + color[0] + ', ' + color[1] + ', ' + color[2] + ', 1)';
        this.datoscolor.color = 'rgba(' + color[0] + ', ' + color[1] + ', ' + color[2] + ', 0.2)';
        this.datoscolor.colortexto = 'rgb(' + colortexto[0] + ', ' + colortexto[1] + ', ' + colortexto[2] + ')';
      }
    },
    darkenColor(rgb, amount) {
      console.log(rgb);
      const [r, g, b] = rgb;

      // Asegurarse de que los valores estén dentro del rango [0, 255]
      const clamp = (value) => Math.max(0, Math.min(value, 255));

      // Oscurecer los componentes R, G y B por el valor "amount"
      const darkenedR = clamp(r - amount);
      const darkenedG = clamp(g - amount);
      const darkenedB = clamp(b - amount);

      // Devolver el color oscurecido como un array [r, g, b]
      return [darkenedR, darkenedG, darkenedB];
    },
    obtener(tipo) {
      switch (tipo) {
        case 'dia':
          return moment().format('D');
        case 'diasem':
          return moment().format('dddd');
        case 'mes':
          return moment().format('MMMM');
        case 'hora':
          return moment().format('H:mm');
      }
    },
    obtenerMensajeBienvenida() {
      if (this.datos['bienvenida'] != 'Bienvenid@') {
        if (this.registros[0]['tipoRegistro'] != null) {
          return 'Tu jornada hoy es de ' + this.horasTrabajo + '';
        }
      }
    },
    girarBoton(options) {
      this.botonAjustes = true;
      if (options) {
        this.options = true;
      }
      // Después de un tiempo, remueve la clase para detener la animación
      setTimeout(() => {
        this.botonAjustes = false;
      }, 1000);
    },
    finish(datos) {
      this.datos = datos;
      this.actualizarEstado();
    },
    actualizarEstado() {
      switch (this.registros[0]['tipoRegistro']) {
        case 'Pausa':
          this.datos['estado'] = 'En pausa'
          this.datos['icono'] = 'el-icon-video-pause'
          this.datos['clase'] = 'pausa'
          break;
        case 'Fin':
          this.datos['estado'] = 'Finalizado'
          this.datos['icono'] = 'el-icon-check'
          this.datos['clase'] = 'white'
          break;
        case null:
          this.datos['estado'] = 'Sin iniciar'
          this.datos['icono'] = 'el-icon-timer'
          this.datos['clase'] = 'white'
          break;
        default:
          this.datos['estado'] = 'En curso'
          this.datos['icono'] = 'el-icon-loading'
          this.datos['clase'] = ''
          break;
      }
      this.init();
    },
    obtenerRegistros(registros) {
      this.registros = registros;
    },
    init() {
      let hora1;
      let hora2;
      let minutostotales;
      let minutosPausas;
      hora1 = moment();
      hora2 = moment(this.registros[this.registros.length - 1]['hora'], 'HH:mm:ss');
      minutostotales = ((hora1.hour() * 60) + hora1.minutes()) - ((hora2.hour() * 60) + hora2.minutes());
      if (this.registros[0]['tipoRegistro'] == 'Reanudacion') {
        for (let i = 0; i < this.registros.length - 1; i += 2) {
          if (this.registros[i]['tipoRegistro'] == 'Reanudacion') {
            hora1 = moment(this.registros[i]['hora'], 'HH:mm:ss');
            hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
            minutostotales -= ((hora1.hour() * 60) + hora1.minutes()) - ((hora2.hour() * 60) + hora2.minutes());
          }
        }
      }
      if (this.registros[0]['tipoRegistro'] == 'Pausa') {
        minutostotales = 0;
        for (let i = 0; i < this.registros.length; i += 2) {
          if (this.registros[i]['tipoRegistro'] == 'Pausa') {
            hora1 = moment(this.registros[i]['hora'], 'HH:mm:ss');
            hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
            minutostotales += ((hora1.hour() * 60) + hora1.minutes()) - ((hora2.hour() * 60) + hora2.minutes());
            hora1 = moment();
            hora2 = moment(this.registros[this.registros.length - 1]['hora'], 'HH:mm:ss');
            minutosPausas = ((hora1.hour() * 60) + hora1.minutes()) - ((hora2.hour() * 60) + hora2.minutes()) - minutostotales;
          }
        }
      }
      if (this.registros[0]['tipoRegistro'] == 'Fin') {
        clearInterval(this.interval);
        minutostotales = 0;
        for (let i = 0; i < this.registros.length - 1; i += 2) {
          hora1 = moment(this.registros[i]['hora'], 'HH:mm:ss');
          hora2 = moment(this.registros[i + 1]['hora'], 'HH:mm:ss');
          minutostotales += ((hora1.hour() * 60) + hora1.minutes()) - ((hora2.hour() * 60) + hora2.minutes())
        }
        hora1 = moment(this.registros[0]['hora'], 'HH:mm:ss');
        hora2 = moment(this.registros[this.registros.length - 1]['hora'], 'HH:mm:ss');
        minutosPausas = ((hora1.hour() * 60) + hora1.minutes()) - ((hora2.hour() * 60) + hora2.minutes()) - minutostotales;
      }
      this.hh = Math.floor(minutostotales / 60)
      this.mm = minutostotales - (this.hh * 60)
      this.hh_pausa = Math.floor(minutosPausas / 60)
      this.mm_pausa = minutosPausas - (this.hh_pausa * 60)
      if (isNaN(this.hh_pausa)) {
        this.hh_pausa = this.hh;
      }
      if (isNaN(this.mm_pausa)) {
        this.mm_pausa = this.mm;
      }
    }
  }
};

</script>


<style lang="scss">
@media only screen and (max-width: 400px) {
  #mainpinpad {
    padding: 0px;
  }

  #contenedorSalida {
    min-height: 30px!important;

    div{
      font-size: 1rem!important;
     margin: 0px!important;
     padding: 0px!important;
    }
  }

  .contenedorNumeros li {
    width: 25%!important;
    border-radius: 10px!important;
    height: 45px!important;
  }
  h2 {
    font-size: 1.2rem;
    margin-top: 0px!important;
  }
  #datosusuario img {
    max-width: 50px;
    max-height: 50px;
  }

  .el-progress-circle {
    max-width: 70px;
    max-height: 70px;
  }

  .contador span {
    font-size: 1.5rem !important;
  }

  .is-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 30px;
    max-width: 30px;

    span {
      svg {
        font-size: 0.8rem !important;
      }
    }
  }
}

* {
  font-family: 'Exo 2', sans-serif;
}

body {
  margin: 0;
}

.estado {
  position: relative;
  margin: 5px;

  span {
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    top: 60%;
    margin-top: 10px;
    padding: .55rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
    color: #000;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
      0 5px 15px rgba(0, 0, 0, 0.07);
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    span {
      top: 100%;
      opacity: 0.9;
    }

    color: gray;

    &::after {
      position: absolute;
      content: "";
      width: 25.59px;
      height: 25.59px;
      background-color: rgb(0, 0, 0);
      top: 0;
      left: 0;
      border-radius: 50%;
      opacity: 0.3;
    }

    &:active {
      &::after {
        position: absolute;
        content: "";
        width: 25.59px;
        height: 25.59px;
        background-color: rgb(0, 0, 0);
        top: 0;
        left: 0;
        border-radius: 50%;
        opacity: 0.5;
      }
    }
  }
}

.selectorestado {
  border-radius: 50%;
  border: 3px solid transparent;
  background: rgba(0, 0, 0, 0.5);
  width: 50px;
  height: 50px;
  position: relative;
  opacity: 0;
  transition: 0.3s;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }

  &.selected {
    opacity: 1;
  }

  &:active {
    background: rgba(0, 0, 0, 0.5);
  }
}

.iconoconfig {
  border-radius: 50%;
  transition: 0.5s;
  padding: 5px;

  &:hover {
    background: #00000075;
  }
}

.iconosalir {
  border-radius: 10px;
  transition: 0.5s;
  padding: 5px;

  &:hover {
    background: #00000075;
  }
}

#contenedorlogo {
  display: flex;
  padding: 10px;
  margin: auto;
  margin-bottom: 10px;
  justify-content: center;
  transition: 0.5s;
  padding: 20px;
  width: 100%;

  img {
    max-height: 100px;
    max-width: 70%
  }

  @media (min-width: 990px) {
    display: none
  }
}

#version {
  opacity: 0;
  color: rgb(0, 107, 28);
  position: fixed;
  bottom: 0;
  left: 0;
  transition: 0.5s;

  &:hover {
    opacity: 1;
  }
}

#rowprincipal {
  display: flex;
  flex-wrap: wrap;
  margin: 0 !important;
  align-items: center;

  #colizquierda {
    position: relative;
    background-size: cover;
    display: flex;
    overflow: hidden;
    padding-left: 0px !important;
    padding-right: 0px !important;

    .instrucciones {
      color: #33438a;
      width: -webkit-fill-available;
      text-align: right;
      padding-bottom: 20px;
      transition: 2s;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 2rem;
        margin: 20px;
        transition: 0.5s;

        @media(min-width: 990px) {
          font-size: 4rem;
        }
      }

      .instrucciones-msg {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        margin: auto;
        position: absolute;
        height: 100vh;
        width: 100%;
        justify-content: center;
      }

      .contenedorContador {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
        z-index: 1;

        @media (max-width: 990px) {
          display: none;
        }
      }
    }

    #logoempresa {
      display: none;
      position: absolute;
      max-width: 40%;
      max-height: 150px;
      left: 20px;
      bottom: 20px;
      transition: 1s;

      @media(min-width: 991px) {
        display: block;
      }
    }

    @media (min-width:990px) {
      height: 100vh;
    }

    @media(max-width: 990px) {
      display: none;
    }
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


/* Prueba dashboard */
.instrucciones-msg {
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  .dashboard {
    width: fit-content;
    text-align: center;
    border: 1px solid rgb(64 109 155 / 10%);
    border-radius: 8px;
    margin-top: 20px;

    .transcurrido {
      padding: 20px 34px;

      &:first-child {
        border-bottom: 1px solid rgb(64 109 155 / 10%);
      }

      p {
        font-size: 1rem !important;
        margin: 0 0 8px 0;
      }

      span {
        font-size: 2rem;
      }
    }
  }
}

#cuenta {
  display: flex;
  justify-content: center;
}

.simply-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: 1s;
  margin-bottom: 0px;

  &:first-child {
    margin-right: 20px;
  }

  @media (min-width: 990px) {
    flex-direction: column;
    background: rgb(225, 243, 216);
    width: 150px;
    height: 100px;
    margin: 0 20px;
    border-radius: 5px;
    width: 150px;
    height: 150px;
    border: 1px solid #e9e9e9;

    &.pausa {
      background: rgb(250, 236, 216);
    }

    &.white {
      background: #FFF;
    }
  }
}

.simply-amount {
  display: block;
  font-size: 30px;
  font-weight: 700;
  color: #409b44;
  transition: 1s;
  margin-right: 5px;

  @media (min-width: 990px) {
    margin: 0;
    font-size: 50px
  }

  &.pausa {
    color: #9b8840;
  }

  &.white {
    color: #84817a;
  }
}

.simply-word {
  font-weight: 300;
  font-size: 20px;
  color: #7dc590;
  transition: 1s;

  @media (min-width: 990px) {
    font-size: 30px
  }

  &.pausa {
    color: #c5af7d;
  }

  &.white {
    color: #aaa69d;
  }
}

#botonajustes {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  display: flex;
  flex-direction: row;

  @media (max-width: 991px) {
    position: fixed;
  }

  i {
    font-size: 1.5rem;
  }
}

.girar {
  animation: girarAnimacion 1s linear infinite;
}

@keyframes girarAnimacion {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}</style>