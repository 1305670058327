import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/es'
import App from './App.vue';
import VueMqtt from 'vue-mqtt';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
/* Import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* Import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* Import all icons */
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

/* Add all icons to the library */
library.add(fas);
library.add(far);
library.add(fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueMqtt, process.env.VUE_APP_WSDOM, {clientId: 'WebClient-' + parseInt(Math.random() * 100000)})
Vue.use(ElementUI, { locale });
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
new Vue({
  el: '#app',
  render: h => h(App)
});